/* eslint-disable react/no-unescaped-entities */
import {useStaticQuery, graphql, Link} from 'gatsby';
import {GatsbyImage, IGatsbyImageData} from 'gatsby-plugin-image';
import React, {ReactElement, PropsWithChildren} from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

interface BookProps {
  img: {gatsbyImageData: IGatsbyImageData};
  url: string;
  title: string;
  authors: string;
}

function Book(props: PropsWithChildren<BookProps>): ReactElement {
  const {img, url, title, authors, children} = props;
  return (
    <div className="book">
      <div className="book-img">
        <a href={url} target="_blank" rel="noopener noreferrer">
          <GatsbyImage image={img.gatsbyImageData} className="border rounded" alt="Book image" />
        </a>
      </div>
      <div className="book-description">
        <a href={url} target="_blank" rel="noopener noreferrer">
          {title}
        </a>{' '}
        by <strong>{authors}</strong>
        <p>{children}</p>
      </div>
    </div>
  );
}

function BookCategory(props: PropsWithChildren<{title: string}>): ReactElement {
  const {title, children} = props;
  return (
    <div className="card mb-3">
      <div className="card-header">{title}</div>
      <div className="card-body">{children}</div>
    </div>
  );
}

const LibraryPage: React.FC = () => {
  const data = useStaticQuery(graphql`
    fragment BookImageFields on File {
      childImageSharp {
        gatsbyImageData(layout: FIXED, width: 150)
      }
    }
    query {
      bookStack: file(relativePath: {eq: "book-stack.jpg"}) {
        ...BookImageFields
      }

      atomicHabits: file(relativePath: {eq: "books/atomic-habits.jpg"}) {
        ...BookImageFields
      }
      caseInPoint: file(relativePath: {eq: "books/case-in-point.png"}) {
        ...BookImageFields
      }
      caseInterviewSecrets: file(relativePath: {eq: "books/case-interview-secrets.jpg"}) {
        ...BookImageFields
      }
      deepWork: file(relativePath: {eq: "books/deep-work.jpg"}) {
        ...BookImageFields
      }
      difficultConversations: file(relativePath: {eq: "books/difficult-conversations.jpg"}) {
        ...BookImageFields
      }
      disciplineEqualsFreedom: file(relativePath: {eq: "books/discipline-equals-freedom.jpg"}) {
        ...BookImageFields
      }
      emotionalIntelligence: file(relativePath: {eq: "books/emotional-intelligence.jpg"}) {
        ...BookImageFields
      }
      extremeOwnership: file(relativePath: {eq: "books/extreme-ownership.jpg"}) {
        ...BookImageFields
      }
      gettingPastNo: file(relativePath: {eq: "books/getting-past-no.jpg"}) {
        ...BookImageFields
      }
      gettingToYes: file(relativePath: {eq: "books/getting-to-yes.jpg"}) {
        ...BookImageFields
      }
      gtdImage: file(relativePath: {eq: "books/get-things-done.jpg"}) {
        ...BookImageFields
      }
      hooked: file(relativePath: {eq: "books/hooked.jpg"}) {
        ...BookImageFields
      }
      howNotToBeWrong: file(relativePath: {eq: "books/how-not-to-be-wrong.jpg"}) {
        ...BookImageFields
      }
      intelligentAssetAllocator: file(relativePath: {eq: "books/intelligent-asset-allocator.jpg"}) {
        ...BookImageFields
      }
      intelligentInvestor: file(relativePath: {eq: "books/intelligent-investor.jpg"}) {
        ...BookImageFields
      }
      personalMba: file(relativePath: {eq: "books/personal-mba.jpg"}) {
        ...BookImageFields
      }
      powerOfHabit: file(relativePath: {eq: "books/power-of-habit.jpg"}) {
        ...BookImageFields
      }
      randomWalk: file(relativePath: {eq: "books/random-walk.jpg"}) {
        ...BookImageFields
      }
      taxLegalPlaybook: file(relativePath: {eq: "books/tax-legal-playbook.png"}) {
        ...BookImageFields
      }
      twelveRulesImage: file(relativePath: {eq: "books/twelve-rules-for-life.jpg"}) {
        ...BookImageFields
      }
      whatYourCpaIsntTelling: file(relativePath: {eq: "books/what-your-cpa-isnt-telling.jpg"}) {
        ...BookImageFields
      }
      zeroToOne: file(relativePath: {eq: "books/zero-to-one.jpg"}) {
        ...BookImageFields
      }
    }
  `);

  return (
    <Layout>
      <SEO title="Library" />

      <h1>Library</h1>
      <p>
        If you want to build up, you have to read. There are a lot of interesting people in the
        world who share a lot of interesting knowledge, and reading their books lets you pick their
        brains. In fact, most ideas in this blog aren't my own, but are the things I read, applied,
        adapted, and found worthy.
      </p>
      <p>
        This list is dynamic: I'll be adding and modifying books here as I read them, so check it
        back again from time to time if you follow this blog!
      </p>
      <p>
        For all the books here, I recommend reading actual books, and not audio versions. This is
        because you need to sit down, focus, and digest these books. They're not easy reads. I call
        this "<Link to="https://buildup.page">Deep Reading</Link>."
      </p>

      <BookCategory title="General">
        <Book
          title="Extreme Ownership: How U.S. Navy SEALs Lead and Win"
          authors="Jocko Willink and Leif Babin"
          url="https://amzn.to/2rLq4Ui"
          img={data.extremeOwnership.childImageSharp}
        >
          Probably the best book I ever read about leadership. The idea is simple, but the
          applications are hard and not always intuitive. My main take on this is: even if
          everything isn't your fault, you're better off acting like it is. I'm also a regular
          listener to <a href="http://jockopodcast.com">Jocko Podcast</a> (especially the Q/A
          episodes), which has TONS of great wisdom.
        </Book>
        <hr />
        <Book
          title="12 Rules for Life: An Antidote to Chaos"
          authors="Jordan B. Peterson"
          url="https://amzn.to/2wLIDN3"
          img={data.twelveRulesImage.childImageSharp}
        >
          This book is not easy to read since it talks about very complicated topics and ideas. I
          took a lot more time with this book and generated many bookmarks. I had to re-read many
          paragraphs. But it was worth every single minute - many things that I didn't understand
          before made sense to me after reading this book. What I liked about this book and the
          author, is that it digests very complicated ideas and understandably presents them. I wish
          this book were available earlier since I could have used these ideas!
        </Book>
        <hr />
        <Book
          title="How Not to Be Wrong: The Power of Mathematical Thinking"
          authors="Jordan Ellenberg"
          url="https://www.amazon.com/How-Not-Be-Wrong-Mathematical/dp/0143127535"
          img={data.howNotToBeWrong.childImageSharp}
        >
          Great book about how to process mathematical facts and statistics. How I analyze news and
          facts has completely changed after I read this book, and I learned how numbers could be
          twisted to fit your desired narrative.
        </Book>
      </BookCategory>

      <BookCategory title="Productivity">
        <Book
          title="Discipline Equals Freedom: Field Manual"
          authors="Jocko Willink"
          url="https://www.amazon.com/Discipline-Equals-Freedom-Field-Manual/dp/1250156947"
          img={data.disciplineEqualsFreedom.childImageSharp}
        >
          <em>Extreme Ownership</em> talks about leadership. This book is about leading yourself;
          having discipline over various aspects of your life. The premise is simple: if you have
          discipline and get things done, you'll have the freedom to enjoy things you gain as a
          result of that discipline.
        </Book>
        <hr />
        <Book
          title="Deep Work: Rules for Focused Success in a Distracted World "
          authors="Calvin Newport"
          url="https://www.amazon.com/gp/product/1455586692/"
          img={data.deepWork.childImageSharp}
        >
          A fantastic book that talks about the value of focused work and how to get there. This
          book resonated with me, because I always believed that the number of hours you work
          doesn't matter if those hours are not productive - rather than caring about much I worked,
          I counted how much I got done and/or how much I learned.
        </Book>
        <hr />
        <Book
          title="Getting Things Done: The Art of Stress-Free Productivity"
          authors="David Allen"
          url="https://amzn.to/2In57cF"
          img={data.gtdImage.childImageSharp}
        >
          I became interested in getting organized when I was moving from apartment to apartment,
          and I didn't have a grasp of where my things are and what's important. Getting Things Done
          (GTD) is probably one of the most popular and "battle-tested" organization systems out
          there, so definitely give it a shot. I don't follow it 100%, and I wouldn't expect anyone
          to do so, but it's worth to develop a personal system based on this.
        </Book>
        <hr />
        <Book
          title="The Power of Habit: Why We Do What We Do in Life and Business"
          authors="Charles Duhigg"
          url="https://www.amazon.com/Power-Habit-What-Life-Business/dp/081298160X/"
          img={data.powerOfHabit.childImageSharp}
        >
          A great book which talks about the importance and impact of habits and how to build them.
          Habits are crucial for building up because you fall back on them when you're tired. It's
          easier to build a good habit, rather than to force yourself to do the right thing every
          time.
        </Book>
        <hr />
        <Book
          title="Atomic Habits"
          authors="James Clear"
          url="https://www.amazon.com/gp/product/0735211299/"
          img={data.atomicHabits.childImageSharp}
        >
          A more practical guide on how to build good habits, and how to avoid bad habits. I
          recommend reading this after "The Power of Habit".
        </Book>
      </BookCategory>

      <BookCategory title="Working with People">
        <Book
          title="Emotional Intelligence: Why It Can Matter More Than IQ"
          authors="David Coleman"
          url="https://www.amazon.com/dp/055338371X/"
          img={data.emotionalIntelligence.childImageSharp}
        >
          This book dives into the science of why we have emotions, and how we can master them. EQ
          matters a lot when you work with people, and I believe you can practice to improve it. I
          got a lot of insights about why I felt certain things, and how I should handle that in the
          best way.
        </Book>
        <hr />
        <Book
          title="Getting Past No: Negotiating in Difficult Situations"
          authors="William Ury"
          url="https://www.amazon.com/Getting-Past-Negotiating-Difficult-Situations/dp/0553371312/"
          img={data.gettingPastNo.childImageSharp}
        >
          I read this book a long time ago, and I remember that after applying one of the principles
          I learned in this book, I was very fascinated by how it seemed to have worked like magic.
          Just like emotional intelligence, negotiation skills can also be mastered, and this book
          is a great introduction.
        </Book>
        <hr />
        <Book
          title="Difficult Conversations: How to Discuss What Matters Most"
          authors=" Douglas Stone, Bruce Patton, Sheila Heen"
          url="https://www.amazon.com/Difficult-Conversations-Discuss-What-Matters/dp/0143118447"
          img={data.difficultConversations.childImageSharp}
        >
          Similar to what I said above - a great book about how to have hard conversations with
          people.
        </Book>
        <hr />
        <Book
          title="Getting to Yes: Negotiating Agreement Without Giving In"
          authors="Roger Fisher, William L. Ury, Bruce Patton"
          url="https://www.amazon.com/Getting-Yes-Negotiating-Agreement-Without/dp/0143118757"
          img={data.gettingToYes.childImageSharp}
        >
          After you get past no, you need to get to yes! I believe this book was written before{' '}
          <em>Getting Past No</em>, but I read it afterward. Note that the three books about
          negotiations I mentioned here have common authors, so you can treat these books as one big
          guide for negotiation.
        </Book>
      </BookCategory>

      <BookCategory title="Business">
        <Book
          title="The Personal MBA: Master the Art of Business"
          authors="Josh Kaufman"
          url="https://www.amazon.com/Personal-MBA-Master-Art-Business/dp/1591845572"
          img={data.personalMba.childImageSharp}
        >
          This book provides a good overview of what does it mean to run a business, and gives you
          directions to learn further about the business world - highly recommended if you don't
          know where to start or to ensure that you don't have gaps in your knowledge.
        </Book>
        <hr />
        <Book
          title="Zero to One: Notes on Startups, or How to Build the Future"
          authors="Peter Thiel, Blake Masters"
          url="https://www.amazon.com/Zero-One-Notes-Startups-Future/dp/0804139296"
          img={data.zeroToOne.childImageSharp}
        >
          Peter Thiel is a controversial figure, but I kept an open mind and was surprised by how
          many unintuitive ideas this book has. There are many insights into how business is run in
          Silicon Valley. The book also contains interesting points about macroeconomic issues, such
          as monopoly and competition.
        </Book>
        <hr />
        <Book
          title="Hooked: How to Build Habit-Forming Products"
          authors="Nir Eyal"
          url="https://www.amazon.com/Hooked-How-Build-Habit-Forming-Products/dp/1591847788"
          img={data.hooked.childImageSharp}
        >
          <em>Digital Minimalism</em> talks about how to prevent yourself from being distracted by
          technology. <em>Hooked</em> talks about how to build addictive products. It peeks into the
          "other side" of the equation and teaches you how to create addictive products.
        </Book>
        <hr />
        <Book
          title="Case in Point 10: Complete Case Interview Preparation"
          authors="Marc P. Cosentino"
          url="https://www.amazon.com/Case-Point-10-Interview-Preparation/dp/0986370746"
          img={data.caseInPoint.childImageSharp}
        >
          Upcoming
        </Book>
        <hr />
        <Book
          title="Case Interview Secrets:
        A Former McKinsey Interviewer Reveals How to Get Multiple Job Offers in Consulting"
          authors="Victor Cheng"
          url="https://www.amazon.com/Case-Interview-Secrets-Interviewer-Consulting/dp/0984183523"
          img={data.caseInterviewSecrets.childImageSharp}
        >
          Upcoming
        </Book>
        <hr />
        <Book
          title="What Your CPA Isn't Telling You: Life-Changing Tax Strategies"
          authors="Mark J. Kohler"
          url="https://www.amazon.com/gp/product/1599184168"
          img={data.whatYourCpaIsntTelling.childImageSharp}
        >
          Upcoming
        </Book>
        <hr />
        <Book
          title="The Tax and Legal Playbook: Game-Changing Solutions To Your
        Small Business Questions"
          authors="Mark J. Kohler"
          url="https://www.amazon.com/gp/product"
          img={data.taxLegalPlaybook.childImageSharp}
        >
          Upcoming
        </Book>
      </BookCategory>

      <BookCategory title="Finance/Investing">
        <Book
          title="A Random Walk down Wall Street: The Time-tested Strategy for Successful Investing"
          authors="Burton G. Malkiel"
          url="https://www.amazon.com/Intelligent-Investor-Definitive-Investing-Essentials/dp/0060555661"
          img={data.randomWalk.childImageSharp}
        >
          Upcoming
        </Book>
        <hr />
        <Book
          title="The Intelligent Investor: The Definitive Book on Value Investing."
          authors=" Benjamin Graham, Jason Zweig, Warren E. Buffett"
          url="https://www.amazon.com/Intelligent-Investor-Definitive-Investing-Essentials/dp/0060555661"
          img={data.intelligentInvestor.childImageSharp}
        >
          Upcoming
        </Book>
        <hr />
        <Book
          title="The Intelligent Asset Allocator: How to Build Your Portfolio to
          Maximize Returns and Minimize Risk"
          authors="William J. Bernstein"
          url="https://www.amazon.com/Intelligent-Asset-Allocator-Portfolio-Maximize/dp/1260026647"
          img={data.intelligentAssetAllocator.childImageSharp}
        >
          Upcoming
        </Book>
      </BookCategory>
    </Layout>
  );
};

export default LibraryPage;
